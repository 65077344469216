import Seo from '../../components/seo'
import './style.scss'

const LegalPageTemplate = ({
  pageContext,
}: {
  pageContext: {
    html: string
    frontmatter: { title: string; effectiveDate: string }
  }
}) => {
  return (
    <>
      <div
        className="LegalPage__background"
        style={{
          backgroundImage: `url(https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/bg-curtains.svg)`,
        }}
      ></div>
      <main className="LegalPage">
        <div>
          <h1>{pageContext.frontmatter.title}</h1>
          <p className="LegalPage__effectiveDate">
            Effective date: {pageContext.frontmatter.effectiveDate}
          </p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: pageContext.html || '',
          }}
        />
      </main>
    </>
  )
}

export const Head = (props: {
  pageContext: { frontmatter: { title: string } }
}) => {
  return <Seo title={`${props.pageContext.frontmatter.title} | PennyLane`} />
}

export default LegalPageTemplate
